import React, { useCallback, useContext, useMemo, useState } from "react";
import { HeaderB } from "components/headers";
import lang from "translations";
import { Path } from "paths";
import { VenueContext } from "contexts";
import { useApi, useMount, useFilter, useRouter, useModal } from "hooks";
import { getPaymentGatewaysByVenueId, searchPaymentGatewayByName } from "apis/payment-gateway.api";
import { DataTable, Icon, Input, Text, Switch } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { SearchOutlined } from "@ant-design/icons";
import { OctopusSunbathing } from "images";
import DeletePaymentGatewayModal from "./delete-payment-gateway-modal/delete-payment-gateway-modal";
import SetDefaultPaymentGatewaySetting from "./set-default-payment-gateway-modal/set-default-payment-gateway-modal";
import { mixpanel, TrackEvent } from "mixpanel";

const PaymentGatewayList = () => {
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;

  const [searchKey, setSearchKey] = useState("");
  const { history } = useRouter();
  const deleteModal = useModal();
  const setDefaultModal = useModal();

  const { modifyFilters, filterState } = useFilter({
    page: 1,
    pageSize: 20,
  });

  const {
    request,
    loading,
    error,
    result = { data: { content: [], totalElements: 0 } },
  } = useApi({
    api: searchKey.trim() ? searchPaymentGatewayByName : getPaymentGatewaysByVenueId,
    params: {
      displayName: searchKey.toString().trim(),
      venueId,
    },
    ...filterState,
  });

  useMount(() => {
    request();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.paymentsListSettings,
    });
  });

  const setDefaultRequest = useCallback(
    async (id, isDefault) => {
      setDefaultModal.show({
        id: id,
        isDefault: isDefault,
        venueId,
      });
    },
    [setDefaultModal, venueId]
  );

  const renderIsDefault = useCallback(
    (id, isDefault) => {
      return (
        <Switch
          value={isDefault}
          onChange={() => {
            setDefaultRequest(id, !isDefault);
          }}
        />
      );
    },
    [setDefaultRequest]
  );

  const renderData = useMemo(() => {
    return result.data?.content?.map(
      ({ id, merchantId, displayName, isDefault, maximumTopUp, minimumTopUp, mdr }) => {
        return {
          id: id,
          merchantId,
          displayName: (
            <div className="flex align-center items-center">
              <Text>{displayName}</Text>
            </div>
          ),
          isDefault: renderIsDefault(id, isDefault),
          minimumTopUp,
          maximumTopUp,
          mdr,
        };
      }
    );
  }, [result, renderIsDefault]);

  const goToAdd = useCallback(() => {
    history.push(Path.PAYMENT_GATEWAY_SETTING_ADD);
  }, [history]);

  return (
    <ModuleWrapper>
      <HeaderB
        returnPath={Path.SETTING}
        returnText={lang.settings}
        title={lang.paymentsSettings}
        button={{
          text: lang.addPaymentGateway,
          onClick: () => {
            goToAdd();
          },
        }}
        className="mb-md"
      />

      <Input
        className="mb-md"
        value={searchKey}
        disabled={loading}
        clearable
        onClear={async () => {
          setSearchKey("");
          await request();
        }}
        onChange={(name, { value }) => {
          setSearchKey(value);
        }}
        onEnter={async () => {
          await request();
          setSearchKey(searchKey);
        }}
        placeholder={lang.searchPaymentGateway}
        iconSuffix={
          loading ? (
            <Icon loading />
          ) : (
            <SearchOutlined
              className="hover:text-blue cursor-pointer"
              onClick={async () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.searchTag,
                  Page: lang.banTagListSettings,
                });
                await request();
                setSearchKey(searchKey);
              }}
            />
          )
        }
      />

      {!loading && renderData.length > 0 && searchKey !== "" ? (
        <p className="text-sm text-gray-400">{lang.searchResults}</p>
      ) : null}
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={request}
        total={result?.data?.totalElements}
        loading={loading}
        columns={[
          {
            key: "displayName",
            text: lang.paymentGatewayName,
            classNames: "whitespace-nowrap",
          },
          {
            key: "minimumTopUp",
            text: lang.minimumTopUp,
            classNames: "whitespace-nowrap",
          },
          {
            key: "maximumTopUp",
            text: lang.maximumTopUp,
            classNames: "whitespace-nowrap",
          },
          {
            key: "mdr",
            text: lang.mdr,
            classNames: "whitespace-nowrap",
          },
          {
            key: "isDefault",
            text: lang.setToDefault,
            custom: true,
            width: "w-1/4",
          },
          {
            key: "action",
            actions: true,
            align: "right",
            actionTitle: lang.options,
            actionOptions: [
              {
                text: lang.view,
                onClick: ({ id }) => {
                  history.push(Path.PAYMENT_GATEWAY_SETTING_VIEW_ID(id));
                },
              },
              {
                text: lang.edit,
                onClick: ({ id }) => {
                  history.push(Path.PAYMENT_GATEWAY_SETTING_EDIT_ID(id));
                },
              },
              {
                text: <Text danger>{lang.delete}</Text>,
                onClick: ({ id }) => {
                  deleteModal.show({
                    id: id,
                  });
                },
              },
            ],
          },
        ]}
        data={renderData}
        error={error}
        hasAppliedFilter={searchKey}
        renderEmpty={{
          image: OctopusSunbathing,
          title: lang.noPaymentGatewaysFound,
        }}
      />      

      <DeletePaymentGatewayModal {...deleteModal} refreshList={() => request()} />

      <SetDefaultPaymentGatewaySetting {...setDefaultModal} refreshList={() => request()} />
    </ModuleWrapper>
  );
};

export default PaymentGatewayList;
