import { searchDevice } from "apis";
import { VenueContext } from "contexts";
import { useApi, useFilter, useModal, useMount } from "hooks";
import useFilterStore, { filterName } from "hooks/filterStore";
import { deviceListFilterRequest, deviceListResponse } from "mappers";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { mixpanel } from "mixpanel";
import lang from "translations";
import { mapObject } from "services";
import { ButtonLink, DataTable, Image, Text } from "components";
import columns from "./columns";
import { ArtOctopus, NotificationIcon } from "images";
import UpdateVersionModal from "./update-version-modal";
import { getLastVersion } from "apis/device.api";
import moment from "moment";
import { EMode } from "enums";

const filterTxt = filterName.device;

const List = () => {
  const { venue } = useContext(VenueContext);
  const { setState: setFilterState, getState } = useFilterStore();
  const updateVersionModal = useModal();
  const [lastVersion, setLastVersion] = useState();

  const {
    request: searchDeviceRequest,
    loading: loadingDevice,
    result: searchDeviceResult = { data: [], metadata: { total: 0 } },
    mappedData,
    error,
  } = useApi({
    api: searchDevice,
    isArray: true,
    mapper: deviceListResponse,
  });

  let startDate, endDate;

  if (venue.defaultMode === EMode.Event && venue.transactionStartDateTime && venue.transactionEndDateTime) {
    startDate = moment(venue.transactionStartDateTime);
    endDate = moment(venue.transactionEndDateTime);
  } else {
    startDate = moment("00:00:00", "HH:mm:ss"); 
    endDate = moment("23:59:00", "HH:mm:ss"); 
  }

  const defaultFilter = {
    venueId: venue.venueId,
    page: 1,
    pageSize: 20,
    sort: { key: "lastSync", value: "desc" },
    dateRange: [startDate, endDate],
    searchKey : "",
  };
  const { modifyFilters, filterState, requestState } = useFilter(
    filterTxt === getState().name && !isEmpty(getState().filter)
      ? getState().filter
      : defaultFilter,
    defaultFilter
  );

  const fetchDeviceTracking = async (totalOutDateDevices) => {
    try {
      const data = await getLastVersion({ venueId: venue.venueId });
      setLastVersion(data.data.versionName);
      if (totalOutDateDevices) {
        updateVersionModal.show({ ...data.data });
      }
    } catch (error) {}
  };

  useMount(async () => {
    if (filterTxt !== getState().name)
      setFilterState({
        name: filterTxt,
        filter: {},
      });
    const res = await fetchDevices(requestState);
    await fetchDeviceTracking(res.metadata?.totalOutDateDevices);
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.deviceList,
    });
  });

  const fetchDevices = useCallback(
    async (requestState) => searchDeviceRequest(mapObject(requestState, deviceListFilterRequest)),
    [searchDeviceRequest]
  );

  const fetchUpdateStore = useCallback(
    async (requestState, filterState) => {
      setFilterState({
        name: filterTxt,
        filter: filterState,
      });
      fetchDevices(requestState);
    },
    [fetchDevices, setFilterState]
  );

  const renderLastSync = useCallback(
    (lastSync) => (
      <div>
        <Text>{lastSync?.date}</Text>
        {lastSync?.time && <Text color="text-gray">{lastSync.time}</Text>}
      </div>
    ),
    []
  );

  const prepareDeviceList = useCallback(() => {
    return mappedData.map((device) => {
      const { id, name, imei, lastSync, serialNumber, appVersion, appDate } = device;
      return {
        name: (
          <ButtonLink
            className="text-left"
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                Button: lang.viewDevice,
                Page: lang.deviceList,
              });
            }}
            path={Path.DEVICE_ID(id)}
          >
            {name}
          </ButtonLink>
        ),
        imei: <Text tagUid>{imei || serialNumber}</Text>,
        appVersion,
        appDate,
        lastSync: renderLastSync(lastSync),
      };
    });
  }, [mappedData, renderLastSync]);

  const devices = useMemo(() => {
    return prepareDeviceList();
  }, [prepareDeviceList]);

  const sortCb = useCallback(
    ({ value, key }) => {
      const { requestState, filterState } = modifyFilters({ sort: { key, value } });
      fetchUpdateStore(requestState, filterState);
    },
    [fetchUpdateStore, modifyFilters]
  );

  return (
    <>
      {!!searchDeviceResult.metadata?.totalOutDateDevices && (
        <WarningLastVersion
          outDateDevices={searchDeviceResult.metadata.totalOutDateDevices}
          total={searchDeviceResult.metadata.total}
          version={lastVersion}
        />
      )}
      <DataTable
        page={filterState.page}
        pageSize={filterState.pageSize}
        onChangePage={modifyFilters}
        fetchList={fetchUpdateStore}
        total={searchDeviceResult.metadata.total}
        loading={loadingDevice}
        columns={columns}
        sort={filterState.sort}
        setSort={sortCb}
        data={devices}
        error={error}
        minWidth="900px"
        renderEmpty={{
          image: ArtOctopus,
          title: lang.noDevicesFound,
        }}
      />
      <UpdateVersionModal {...updateVersionModal} />
    </>
  );
};

export default List;

const WarningLastVersion = ({ version, total, outDateDevices }) => {
  return (
    <div className="bg-white p-3 mb-4 flex gap-8 items-center">
      <Image src={NotificationIcon} className="w-13 h-13" />

      <div className="text-base leading-7 ">
        <Text size="text-base">
          {lang.theLastVersion}
          {version}
        </Text>
        <lang.Translate
          text={lang.showNumOutDateDevices}
          items={[
            <span className="text-red">
              {outDateDevices}/{total}
            </span>,
          ]}
        />
      </div>
    </div>
  );
};
