import { transactionDateStore } from "hooks";

export const refundClaimReportFilterState = (venue) => {
  const { venueId, startDate, endDate } = transactionDateStore(venue);
  return {
    venueId,
    page: 1,
    pageSize: 20,
    dateRange: [startDate, endDate],
    status: "REFUNDED",
    searchKey: "",
  };
};
